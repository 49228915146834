$(document).ready(function() {

  animateSequence();
  checkRemainderPortLogos();

  $('.fade-in-on-load').addClass('appear');

  var initScrollPosition = $(document).scrollTop();
  if(initScrollPosition <= 5) {
    $('.icon-only-fixed').addClass('icon-only');
    $('.icon-only').removeClass('icon-only-fixed');
    $('.text-only').css('opacity', '1');
  } else if (initScrollPosition > 5) {
    $('.icon-only').addClass('icon-only-fixed');
    $('.icon-only-fixed').removeClass('icon-only');
  }

  

  $(function () {
    console.log('Checked Article Count:' + $('.checked-article').length);
    $(".checked-article").slice(0, 12).show();
    if($(".checked-article").length < 12) {
      $(".load-more-articles").css("visibility", "hidden");
    }
    $(document).on('click touchstart', '.load-more-articles', function(e) {
      e.preventDefault();
      var firstHidden = $(".checked-article:hidden").first();
      $(".checked-article:hidden").slice(0, 12).slideDown("slow");
      firstHidden.focus();
      if($(".checked-article:hidden").length == 0) {
        $(".load-more-articles").css("visibility", "hidden");
      }
    });
  });

  checkboxDropdown(".article-filter-dropdown");

  $(document).scroll(appearOnScroll);

  function appearOnScroll() {
    var fadeInEls = document.querySelectorAll('.fade-in');

    for(var i = 0; i < fadeInEls.length; i++) {
      var windowHeight = window.innerHeight;
      var elTop = fadeInEls[i].getBoundingClientRect().top;
      if(elTop < windowHeight - 200) {
        fadeInEls[i].classList.add('appear');
      }
    }
  }

  


  $(document).scroll(function(e) {
    var scrollPosition = $(document).scrollTop();

    if(scrollPosition > 10) {
      $('header').addClass('scrolled');
    } else if (scrollPosition <= 10) {
      $('header').removeClass('scrolled');
    }

    if($('body').is('.home-body')) {
      var compensation = $(window).height() / 2;
      var contentFirst = ($('.content-first').offset().top) - compensation;
      var screenGraphic = ($('.computer-screen').offset().top) - compensation;
      var animationRow = ($('.animation-row').offset().top) - compensation;

      if(scrollPosition > screenGraphic) {
        $('.pop-in-animation-one').addClass('active');
        $('.pop-in-animation-two').addClass('active');
        $('.pop-in-animation-three').addClass('active');
        $('.pop-in-animation-four').addClass('active');
        $('.pop-in-animation-five').addClass('active');
        $('.slide-out-animation').addClass('active');
      }

      if(scrollPosition <= 5) {
        $('.icon-only-fixed').addClass('icon-only');
        $('.icon-only').removeClass('icon-only-fixed');
        $('.text-only').css('opacity', '1');
      } else if (scrollPosition > 5) {
        $('.icon-only').addClass('icon-only-fixed');
        $('.icon-only-fixed').removeClass('icon-only');
        $('.text-only').css('opacity', '0');
      }

      if(scrollPosition > contentFirst - 1000) {
        cobaltLongDash.runAll();
        setTimeout(() => {
          cobaltShortDash.runAll();
        }, 1500);
      }

      if(scrollPosition > animationRow - 500) {
        cobaltXDash.runAll();
      }
    }
  });
});

$(document).on('click', '.menu-icon', function(e) {
  $(this).toggleClass("menu-icon-active");
  $(".main-nav").toggleClass("main-nav-open");
});

$(document).on('keydown', '.menu-icon', function(e) {
  if (e.which == 13) {
    $(this).toggleClass("menu-icon-active");
    $(".main-nav").toggleClass("main-nav-open");
  }
});

$(document).on('click', '.nav-item a', function(e) {
  $(".menu-icon").toggleClass("menu-icon-active");
  $(".main-nav").toggleClass("main-nav-open");
});

$(document).on('keypress', '.nav-item a', function(e) {
  if (e.which == 13) {
    $(".menu-icon").removeClass("menu-icon-active");
    $(".main-nav").removeClass("main-nav-open");
  }
});

$(document).on('keydown', '.mobile-menu a:last', function(e) {
  if (e.which == 9) {
    e.stopPropagation();
    e.preventDefault();
    $('.menu-icon').focus();
  }
});

$(window).resize(function() {
  $(".menu-icon").removeClass("menu-icon-active");
  $(".main-nav").removeClass("main-nav-open");
  checkRemainderPortLogos()
});

$(document).on('click', '.featured-team-member-card', function(e) {
  var thisObj = $(this);
  openTeamMemberCare(thisObj);
});

$(document).on('keydown', '.featured-team-member-card', function(e) {
  if(e.which == 13) {
    var thisObj = $(this);
    openTeamMemberCare(thisObj);
  }
});

function openTeamMemberCare(thisObj) {
  var containerHeight = $(".our-team-content").height();
  var bioBoxHeight = $(".our-team-right-col").height();
  var width = thisObj.width();
  var height = thisObj.height();
  var marginTopBio = height + 25;
  var ftmCardWidth = $('.active-card').height();

  if ($(window).width() < 768) {
    $(".our-team-content").css("min-height", containerHeight);
    $(".our-team-right-col").css("min-height", bioBoxHeight);
    thisObj.css("width", width);
    $(".ftm-bio").css('margin-top', marginTopBio + 150);
    // $('.close-bio-button').css('top', marginTopBio);
  } else {
    $(".our-team-content").css("height", containerHeight);
    $(".our-team-right-col").css("height", bioBoxHeight);
    thisObj.css("width", width);
  }


  thisObj.addClass("active-card");
  thisObj.removeClass("not-active");
  $(".not-active").fadeOut(300);
  $(".our-team-section").addClass("no-after-el");
  thisObj.addClass("no-after-el");


  var startingPosition = thisObj.position();
  var otHeadingHeight = $('.our-team-heading').height();
  var topInitPos = startingPosition.top;

  setTimeout(() => {
    thisObj.css({ "position": "absolute", "top": topInitPos, "left": startingPosition.left});
    if($(window).width > 768) {
      thisObj.animate({left: '0'}, 300);
    } else {
      thisObj.animate({top: '0', left: '0', right: '0'}, 300);
    }
  }, 300);

  setTimeout(() => {
    if($(window).width() < 768) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.our-team-heading').offset().top
      }, 600);
    }
    var activeID = thisObj.attr('data-member-card');
    $('.ftm-bio[data-member-bio="' + activeID + '"]').fadeIn(300);
    $('.featured-team-member-card[data-member-card="' + activeID + '"] .ftm-linkedin').fadeIn(300);
    $('.close-bio-button[data-member-close="' + activeID + '"]').fadeIn(300);
  }, 800);
};

$(document).on('click', '.close-bio-button', function(e) {
  closeTeamBio(e);
});

$(document).on('keydown', '.close-bio-button', function(e) {
  if(e.which == 13) {
    closeTeamBio(e);
  }
});

$(window).resize(function(e) {
  closeTeamBio(e);
})

function closeTeamBio(e) {
  $(".featured-team-member-card").removeClass("no-after-el");
  $(".our-team-section").removeClass("no-after-el");
  $(".ftm-bio").fadeOut(300);
  $(".ftm-linkedin").fadeOut(300);
  $(".close-bio-button").fadeOut(300);
  $(".active-card").fadeOut(300);
  

  setTimeout(() => {
    $(".ftm-bio").css('margin-top', '');
    $(".active-card").css({'position': '', 'top': '', 'left': ''});
    $(".active-card").fadeIn(300);
    $(".not-active").fadeIn(300);
    $(".featured-team-member-card").removeClass("not-active");
    $(".featured-team-member-card").addClass("not-active");
    $(".our-team-content").removeAttr("style");
    $(".our-team-right-col").removeAttr("style");
    $(".featured-team-member-card").removeAttr("style");
    $(".active-card").removeClass("active-card");
  }, 500);
}

  var cobaltShortDash = {
    runAll:function(){
      var thisobj = this;
      setTimeout(function(){ thisobj.run1(); }, 200);
      setTimeout(function(){ thisobj.run2(); }, 1200);
    },
    run1:function(){
      $("#widget--cobalt-short-dash").find(".cobalt-sd--g1 path").fadeIn(400);
    },
    run2:function(){
      $("#widget--cobalt-short-dash").find(".cobalt-sd--g2 path").each(function(i){
        $(this).delay(i*350).fadeIn(400);
      });
      $('.dash-graphic').addClass('completed');
    }
  };


  var cobaltLongDash = {
    runAll:function(){
      var thisobj = this;
      setTimeout(function(){ thisobj.run1(); }, 200);
      setTimeout(function(){ thisobj.run2(); }, 1200);
      setTimeout(function(){ thisobj.run3(); }, 2700);
    },
    run1:function(){
      $("#widget--cobalt-long-dash").find(".cobalt-ld--g1 path").fadeIn(400);
    },
    run2:function(){
      $("#widget--cobalt-long-dash").find(".cobalt-ld--g2 path").each(function(i){
        $(this).delay(i*100).fadeIn(400);
      });
    },
    run3:function(){
      $("#widget--cobalt-long-dash").find(".cobalt-ld--g3 path").fadeIn(600);
      $('.dash-path-graphic').addClass('completed');
      /*
      $("#widget--cobalt-long-dash").find(".cobalt-ld--g3 path").each(function(i){
        $(this).delay(i*300).fadeIn(400);
      });
      */
    }
  };



  var cobaltXDash = {
    runAll:function(){
      var thisobj = this;
      setTimeout(function(){ thisobj.run1(); }, 200);
      setTimeout(function(){ thisobj.run2(); }, 600);
      setTimeout(function(){ thisobj.run3(); }, 1000);
    },
    run1:function(){
      $("#dashed-x-line").find(".cobalt-x--g1 path").fadeIn(200);
    },
    run2:function(){
      $("#dashed-x-line").find(".cobalt-x--g2 path").each(function(i){
        $(this).delay(i*100).fadeIn(200);
      });
    },
    run3:function(){
      $("#dashed-x-line").find(".cobalt-x--g3 path").fadeIn(600);
      $('.dash-x-graphic').addClass('completed');
      /*
      $("#widget--cobalt-long-dash").find(".cobalt-ld--g3 path").each(function(i){
        $(this).delay(i*300).fadeIn(400);
      });
      */
    }
  };




function checkboxDropdown(el) {
  var $el = $(el);
  function updateStatus(label, result) {
    if(!result.length) {
      label.html('Our Portfolio');
    }
  };
  function updateListings(itemChecked, checkedStatus) {
    $(".article-listing-link[data-category=" + itemChecked + "]").toggleClass("checked-article");
    $(".article-listing-link").hide();
    $(".checked-article").slice(0, 12).show();
    $checkedArticles = $(".checked-article");
    if($checkedArticles.length <= 12) {
      $(".load-more-articles").css("visibility", "hidden");
      if($checkedArticles.length == 0) {
        $(".load-more-row").append('<div class="no-posts-message"><h2>There are no posts in this category. Try filtering through Our Portfolio.</h2></div>');
      } else if ($checkedArticles.length > 0) {
        $(".no-posts-message").remove();
      }
    } else {
      $(".load-more-articles").css("visibility", "visible");
    }
  };

  $el.each(function(i, element) {
    var $list = $(this).find(".af-dropdown-list"),
    $label = $(this).find(".af-dropdown-label"),
    $inputs = $(this).find(".check"),
    defaultChecked = $(this).find("input[type=checkbox]:checked"),
    result = [];
    updateStatus($label, result);
    $(".article-filter-dropdown").on('click', function(){
      $(this).toggleClass('open');
    });
    $(".af-dropdown-label").on('keydown', function(e){
      if (e.which == 13) {
        $(".article-filter-dropdown").toggleClass('open');
      } else if (e.which == 40) {
        $(".af-checkbox:first").focus();

      } else if (e.which = 38) {
        $(".af-checkbox:last").focus();
      }
    });

    $inputs.on('change', function() {
      var thisObj = $(this);
      triggerChanges(thisObj);
    });

    $(".af-checkbox").on('keydown', function(e) {
      if (e.which == 13) {
        var thisCheck = $(this).find('.check');
        triggerChanges(thisCheck);
      } else if (e.which == 40) {
        var lastEl = $('.af-checkbox:last');
        if ($(this).next().length == 0) {
          $('.af-dropdown-label').focus();
        } else {
          $(this).next().focus();
        }
      } else if (e.which = 38) {
        var firstEl = $('.af-checkbox:first');
        if($(this).prev().length == 0) {
          $('.af-dropdown-label').focus();
        } else {
          $(this).prev().focus();
        }
      }
    });

    function triggerChanges(thisObj) {
      var checked = thisObj.is(':checked');
      var checkedText = thisObj.next().text();
      var checkedID = thisObj.attr("id");
      if(checked) {
        result.push(checkedText);
        thisObj.removeAttr("checked");
        // $label.html(result.join(", "));
      } else {
        let index = result.indexOf(checkedText);
        thisObj.attr("checked", "yep");
        if(index >= 0) {
          result.splice(index, 1);
        }
        // $label.html(result.join(", "));
      }
      updateStatus($label, result);
      updateListings(checkedID, checked);
    }

    $(document).on('click touchstart', e=> {
      if(!$(e.target).closest($(this)).length) {
        $(this).removeClass('open');
      }
    });
  });
};

function checkRemainderPortLogos() {
  $numberOfPortLogs = $('.portfolio-logo').length;
  if($(window).width() > 768) {
    if($(window).width() >= 1200) {
      $remainderPL = $numberOfPortLogs % 4;
    } else {
      $remainderPL = $numberOfPortLogs % 2;
    }
    $('.portfolio-logo').removeClass('last-row-pl');
    $('.portfolio-logo').slice(-$remainderPL).addClass('last-row-pl');
  }
}

function animateSequence() {
  var a = $('.sequence');
  const timer = ms => new Promise(res => setTimeout(res, ms));
  
  async function load() {
   for (var i = 0; i < a.length; i++) {
     
      var $this = a[i];
     $($this).css('opacity', '1');
      var letter = $this.innerHTML;
      letter = letter.trim();
      var str = '';
      var delay = 50;
      for (l = 0; l < letter.length; l++) {
        if (letter[l] != ' ') {
          str += '<span style="animation-delay:' + delay + 'ms; -moz-animation-delay:' + delay + 'ms; -webkit-animation-delay:' + delay + 'ms; ">' + letter[l] + '</span>';
          delay += 50;
        } else {
          str += letter[l];
        }
      }
        $this.innerHTML = str;
     await timer(1000);
    }
  }
  
  load();
}